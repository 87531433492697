// extracted by mini-css-extract-plugin
export var accomplish = "quiz-template-page-module--accomplish--b409e";
export var active = "quiz-template-page-module--active--415f4";
export var aiForm = "quiz-template-page-module--ai-form--d02c7";
export var callout = "quiz-template-page-module--callout--4e301";
export var card = "quiz-template-page-module--card--d4957";
export var content = "quiz-template-page-module--content--6c840";
export var coverImg = "quiz-template-page-module--cover-img--01c42";
export var embed = "quiz-template-page-module--embed--490ec";
export var embedContainer = "quiz-template-page-module--embed-container--9673e";
export var formContainer = "quiz-template-page-module--form-container--c60bb";
export var img = "quiz-template-page-module--img--b8ebb";
export var inputContainer = "quiz-template-page-module--input-container--0d45c";
export var nav = "quiz-template-page-module--nav--c3b3e";
export var preview = "quiz-template-page-module--preview--ec51c";
export var previewModal = "quiz-template-page-module--preview-modal--a070c";
export var related = "quiz-template-page-module--related--cfa45";
export var separator = "quiz-template-page-module--separator--8aa27";
export var templateCardInner = "quiz-template-page-module--template-card-inner--7b321";
export var templateCardOuter = "quiz-template-page-module--template-card-outer--ea58b";
export var templatePage = "quiz-template-page-module--template-page--c72de";
export var useTemplate = "quiz-template-page-module--use-template--219e6";
export var useTemplateContainer = "quiz-template-page-module--use-template-container--bd6f9";