// extracted by mini-css-extract-plugin
export var active = "preview-cards-module--active--a030a";
export var answer = "preview-cards-module--answer--384a1";
export var answerContainer = "preview-cards-module--answer-container--f4f6b";
export var answers = "preview-cards-module--answers--185f0";
export var btn = "preview-cards-module--btn--f9c2a";
export var card = "preview-cards-module--card--1cd89";
export var cards = "preview-cards-module--cards--f7d1a";
export var checkbox = "preview-cards-module--checkbox--ee6f1";
export var content = "preview-cards-module--content--c6e43";
export var counter = "preview-cards-module--counter--eccf4";
export var cover = "preview-cards-module--cover--f5657";
export var description = "preview-cards-module--description--428a7";
export var img = "preview-cards-module--img--18dbd";
export var imgAnswerContainer = "preview-cards-module--img-answer-container--61906";
export var question = "preview-cards-module--question--7eccd";
export var questionTitle = "preview-cards-module--question-title--45cd9";
export var result = "preview-cards-module--result--fc3de";
export var resultTitle = "preview-cards-module--result-title--efbdc";
export var title = "preview-cards-module--title--0108a";
export var wrapper = "preview-cards-module--wrapper--6e26a";