import * as styles from "./preview-cards.module.scss"

import React, { Component, memo, useEffect, useRef, useState } from "react"

import LazyImage from "../lazy-img"
import _ from "lodash"

const Cover = ({ data, page }) => {
  const { title, description, design } = data
  const { cover, btnText } = design
  const btnStyles = {
    backgroundColor: design.btnColor || '',
    color: `#ffffff`
  }

  const img = typeof cover !== 'object' ? { url: cover } : cover

  return (
    <div className={ `${ styles.content } ${ styles.cover }` }>
      { !page ? <h1>{ title }</h1> : <p className={ styles.title }>{ title }</p> }
      { img && img.url ? <LazyImage className={ styles.img } activeClassName={ styles.active } src={ img.url } /> : null }
      <div className={ styles.description } dangerouslySetInnerHTML={ { __html: description } }></div>
      <div className={ styles.btn } style={ btnStyles }>{ btnText || 'Take Quiz' }</div>
    </div>
  )
}

const Question = ({ data, page }) => {
  const { index, title, img, settings, options, answerType, total, design } = data
  const style = {
    backgroundColor: (design && design.optionColor) || `#f2f2f2`,
    color: ( design && design.optionFontColor) || `#3c3c3c`
  }

  const qImg = typeof img !== 'object' ? { url: img, hide: false } : img

  return (
    <div className={ `${ styles.content } ${ styles.question }` }>
      { !page ? <h2>{ title }</h2> : <p className={ styles.questionTitle }>{ title }</p> }
      { qImg && qImg.url && qImg.hide === false ? <LazyImage className={ styles.img } activeClassName={ styles.active } src={ qImg.url } /> : null }
      <div className={ styles.answers }>
        {
          options.map(a => {
            return (
              <div className={ answerType === 'text' ? styles.answerContainer : styles.imgAnswerContainer } key={ a.uid }>
                <div className={ styles.answer } style={ style }>
                  { a.img && a.img.url ? <LazyImage className={ styles.img } activeClassName={ styles.active } src={ a.img.url } /> : null }
                  { typeof a.img === 'string' ? <LazyImage className={ styles.img } activeClassName={ styles.active } src={ a.img } /> : null }
                  { a.text ? <p>{ a.text }</p> : null }
                  <div className={ styles.checkbox }>&nbsp;</div>
                </div>
              </div>
            )
          })
        }
      </div>
      { settings && settings.selectMultiple ? <div className={ styles.btn } style={ { backgroundColor: design && design.btnColor } }>Next</div> : null }
      <p className={ styles.counter }>{ index } / { total }</p>
    </div>
  )
}

const Result = ({ data, page }) => {
  const { title, img, description, min, quizType } = data

  return (
    <div className={ `${ styles.content } ${ styles.result }` }>
      { quizType === 'score' ? <p>You scored a { min }</p> : null }
      { !page ? <h2>{ title }</h2> : <p className={ styles.resultTitle }>{ title }</p> }
      { img && img.url && img.hide !== true ? <LazyImage className={ styles.img } activeClassName={ styles.active } src={ img.url } /> : null }
      <div className={ styles.description } dangerouslySetInnerHTML={ { __html: description } }></div>
    </div>
  )
}

const PreviewCard = memo(({ data, type, loaded, page }) => {
  const [ratio, setRatio] = useState(60)
  const [scale, setScale] = useState(1)
  const [hidden, setHidden] = useState(false)

  const card = useRef()
  const wrapper = useRef()

  const onResize = () => {
    if (wrapper && card) {
      const scaleValue = Math.min(
        wrapper.current.offsetWidth / card.current.offsetWidth,
        wrapper.current.offsetHeight / card.current.offsetHeight
      )
      setScale(scaleValue)
      setHidden(false)
    }
  }
  const throttledOnResize = _.throttle(onResize, 250)

  useEffect(() => {
    const ratioTimer = setTimeout(() => setRatio((card.current.offsetHeight / card.current.offsetWidth) * 100), 500)
    const resizeTimer = setTimeout(throttledOnResize, 1000)
    window.addEventListener('resize', throttledOnResize)

    // Clean up resize event listener
    return () => {
      clearTimeout(ratioTimer)
      clearTimeout(resizeTimer)
      window.removeEventListener('resize', throttledOnResize)
    }
  }, [])

  useEffect(() => {
    let ratioTimer, resizeTimer = null

    if (loaded) {
      ratioTimer = setTimeout(() => setRatio((card.current.offsetHeight / card.current.offsetWidth) * 100), 500)
      resizeTimer = setTimeout(throttledOnResize, 1000)
    }

    // Clean up resize event listener
    return () => {
      ratioTimer && clearTimeout(ratioTimer)
      resizeTimer && clearTimeout(resizeTimer)
    }
  }, [loaded])

  const renderContent = () => {
    switch(type) {
      case 'cover': return <Cover data={ data } page={ page } />
      case 'question': return <Question data={ data } page={ page } />
      case 'result': return <Result data={ data } page={ page } />
      default: return
    }
  }

  const { design } = data
  const { bgColor } = design
  const cardStyles = {
    backgroundColor: bgColor || `#ffffff`,
    transform: `translate(-50%, -50%) scale(${ scale && scale < 1 ? scale : 1 })`
  }


  const wrapperStyles = {
    height: `0`,
    paddingBottom: `${ ratio || 0 }%`,
    opacity: hidden ? 0 : 1
  }

  return (
    <div ref={ wrapper } className={ styles.wrapper } style={ wrapperStyles }>
      <div ref={ card } className={ styles.card } style={ cardStyles }>
        { renderContent() }
      </div>
    </div>
  )
})

const PreviewCards = ({ rawData, page }) => {
  const content = JSON.parse(rawData)
  const { design, data, metadata } = content
  const { questions, results } = data

  const { fontType, fontColor } = design
  const { name, weights, source } = fontType || {};
  const [fontLoaded, setFontLoaded] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && !fontLoaded) {
      if (source === 'google') {
        const WebFont = require('webfontloader')
        WebFont.load({
          google: {
            families: [`${ name }:${ weights }`]
          },
          active: () => {
            !fontLoaded && setFontLoaded(true)
          },
        })
      }
    }
    // Have a 3 sec timeout to force loaded font
    setTimeout(() => !fontLoaded && setFontLoaded(true), 3000)
  }, [])

  const style = {
    fontFamily: name ? `${ name }, sans-serif` : ``,
    fontWeight: name ? 300 : 100,
    color: fontColor || `#3c3c3c`,
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <div className={ styles.cards } style={ style }>
        <PreviewCard data={ content } type="cover" loaded={ fontLoaded } page={ page } />
        { questions && questions.map(q => <PreviewCard data={ { ...q, total: questions.length, design } } type="question" loaded={ fontLoaded } key={ q.uid } page={ page }/>) }
        { results && results.map(r => <PreviewCard data={ { ...r, quizType: metadata && metadata.type, design } } type="result" loaded={ fontLoaded } key={ r.uid } page={ page }/>) }
      </div>
    </div>
  )
}

export default PreviewCards